<script>
    import { _ } from 'svelte-i18n'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { updateAccounts } from '$utils/profile'
    import {
        getProfile,
        deleteProvider,
        deleteUnverifiedProvider,
    } from '$utils/api.js'
    import PersonalProviderSection from '$lib/profile/PersonalProviderSection.svelte'
    import ManagedProviderSection from '$lib/profile/ManagedProviderSection.svelte'

    let {
        editMode = false,
        dropdownStates = $bindable({}),
        verifyProvider = $bindable(null),
        verifyProviderAuthority = $bindable(null),
        handleDropdown = () => {},
        continueWithProvider = () => {},
        makePreferred = () => {},
        makeRecovery = () => {},
        removeRecovery = () => {},
    } = $props()

    let confirmProviderDelete = $state()

    let removeProviderAjax = $state(false)
    async function removeProvider(id) {
        removeProviderAjax = confirmProviderDelete
        const res = await deleteProvider(id)
        global.data.profile.accounts = res.accounts
        global.data = updateAccounts(global.data) //add sortrank, sortlabel and unverified accounts to accounts array
        confirmProviderDelete = null
        removeProviderAjax = null
    }

    let removeUnverifiedProviderAjax = $state(false)
    async function removeUnverifiedProvider(user_name) {
        removeUnverifiedProviderAjax = confirmProviderDelete
        if (global.data.profile.unverified_emails.includes(user_name)) {
            const index =
                global.data.profile.unverified_emails.indexOf(user_name)
            await deleteUnverifiedProvider(index, 'email')
        } else {
            const index =
                global.data.profile.unverified_phones.indexOf(user_name)
            await deleteUnverifiedProvider(index, 'phones')
        }
        global.data = await getProfile()
        global.data = updateAccounts(global.data) //add sortrank, sortlabel and unverified accounts to accounts array
        confirmProviderDelete = null
        removeUnverifiedProviderAjax = null
    }

    async function verifyEmailSuccess() {
        global.data = await getProfile()
        global.data = updateAccounts(global.data) //add sortrank, sortlabel and unverified accounts to accounts array
        if (!global.data.merge) {
            if (verifyProvider) {
                notification.show(
                    $_('{contact} has been verified', {
                        values: {
                            contact: verifyProvider,
                        },
                    }),
                    'success',
                )
            }
            verifyProvider = null
        }
        dropdownStates.email = false
        dropdownStates.managedProvider = false
    }
</script>

<PersonalProviderSection
    {editMode}
    bind:dropdownStates
    bind:confirmProviderDelete
    bind:verifyProvider
    bind:verifyProviderAuthority
    {continueWithProvider}
    {removeProviderAjax}
    {removeUnverifiedProviderAjax}
    {handleDropdown}
    {removeProvider}
    {removeUnverifiedProvider}
    {verifyEmailSuccess}
    {removeRecovery}
    {makePreferred}
    {makeRecovery}
/>

<ManagedProviderSection
    {editMode}
    bind:dropdownStates
    bind:confirmProviderDelete
    {removeProviderAjax}
    {removeUnverifiedProviderAjax}
    {handleDropdown}
    {removeProvider}
    {removeUnverifiedProvider}
    {verifyEmailSuccess}
    {removeRecovery}
    {makeRecovery}
/>
