<!-- Wallet Profile -->
<!-- https://wallet.hello.coop/ -->

<script>
    import { onMount } from 'svelte'
    import { _ } from 'svelte-i18n'
    import { replace as replaceRoute } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { setAttributes, cleanUrl } from '$utils/common.js'
    import {
        updateAccounts,
        wizard,
        makeAuthorityNotification,
        logProviderResPlausibleEvents,
    } from '$utils/profile.js'
    import { clearLocalAndSessionStorage } from '$utils/storage.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import {
        getProfile,
        keepAlive,
        putAuthority,
        postLinkProvider,
    } from '$utils/api.js'
    import { deviceTheme } from '$utils/device.js'
    import TitleBar from '$lib/TitleBar.svelte'
    import Notification from '$lib/Notification.svelte'
    import LanguageSection from '$lib/profile/LanguageSection.svelte'
    import ApplicationSection from '$lib/profile/ApplicationSection.svelte'
    import DownloadDataSection from '$lib/profile/DownloadDataSection.svelte'
    import DeviceSection from '$lib/profile/DeviceSection.svelte'
    import NameSection from '$lib/profile/NameSection.svelte'
    import PictureSection from '$lib/profile/PictureSection.svelte'
    import ProviderSection from '$lib/profile/ProviderSection.svelte'
    import ProfileLandingModal from '$lib/modal/ProfileLandingModal.svelte'
    import InfoSection from '$lib/profile/InfoSection.svelte'

    let editMode = $state(false),
        verifyProvider = $state(null),
        verifyProviderAuthority = $state(null),
        remoteAuthDone = $state(false),
        showProfileLandingModal = $state(false)

    let dropdown = $state({
        provider: false,
        managedProvider: false,
        language: false,
        picture: false,
        email: false,
        phone: false,
    })

    // TBD move to onMount -- we are currently using these var outside onmount fn
    const _search = window.location.search
    const _hash = window.location.hash.substring(1) // strips '#'
    const params = new URLSearchParams(_search || _hash)

    onMount(async () => {
        global.spinner = true

        // Process URL State
        const error = params.get('error')
        const provider = params.get('provider')
        const welcome = params.get('welcome')

        const noProfileState = !global.data?.version

        // error & provider order matters currently -- will rework once error handling is reworked
        if (error) await processError()
        else if (provider) await processResponse()
        else if (welcome) await processWelcome()
        else if (noProfileState) await handleNoProfileState()

        cleanUrl()

        // Process API Response

        //Authorization success at IAB
        if (global.data.inApp) {
            remoteAuthDone = true
            notification.show('Authorization is complete', 'success')
            global.spinner = false
            return
        }

        // Redirects to wizard pages if not completed
        if (!wizard(global.data)) {
            if (!global.data.isPersonalLoggedIn) return showLogin()

            // Handle outstanding authority call after verification
            if (sessionStorage.authority) await handleAuthority()

            // Only add unverified emails/phone to accounts upon wizard completion
            if (global.data.actions?.doneWizardAt) {
                global.data = updateAccounts(global.data) //add sortrank, sortlabel and unverified accounts to accounts array
            }

            logPlausibleEvent({ u: '/' })

            if (localStorage.showProfileLandingModal) {
                showProfileLandingModal = true
            }
            if (sessionStorage.isInEditMode) {
                editMode = true
            }

            global.spinner = false
        }
    })

    async function handleAuthority() {
        let authority
        try {
            authority = JSON.parse(sessionStorage.getItem('authority'))
        } catch (err) {
            console.error(err)
            console.warn('Bad data in authority session storage. Clearing...')
            sessionStorage.getItem('authority')
        }
        if (authority) {
            global.spinner = true
            const preferred = authority.preferred
            const recovery = authority.recovery
            const no_recovery = authority.no_recovery
            const account = global.data.profile?.accounts?.find(
                (i) => i.id === (preferred || recovery || no_recovery),
            )
            if (preferred) {
                await makePreferred(account)
            } else if (recovery) {
                await makeRecovery(account)
            } else if (no_recovery) {
                await removeRecovery(account)
            }
            sessionStorage.removeItem('authority')
        }
    }

    async function processError() {
        //this will always fail
        global.data = await getProfile(params.toString())
        const error = global.data.error
        //LINE returns ACCESS_DENIED (uppercase)
        //Apple returns user_cancelled_authorize
        if (
            ['access_denied', 'user_cancelled_authorize'].includes(
                error?.toLowerCase(),
            )
        ) {
            notification.show($_('Request cancelled'), 'error')
        } else {
            notification.show(
                $_('Something went wrong. Please try again later.'),
                'error',
            )
        }

        //User can cancel authorize flow on linking provider - make getProfile call since user can be still logged in
        global.data = await getProfile('', { showNotification: false })
    }

    async function processResponse() {
        params.append('prefers-color-scheme', deviceTheme)
        params.append('language', window.navigator.language)
        const json = await getProfile(params)
        if (json.error) {
            const msg = json.error.message
            // user clicked back after we have already processed response from provider
            // in this case, we fetch get profile again
            if (msg === 'NO_SESSION_RESPONSE') {
                global.data = await getProfile()
                if (global.data?.verifyManagedEmail)
                    notification.show(
                        $_('You must verify your email to proceed'),
                        'error',
                    )
                else
                    notification.show(
                        $_('Looks like you clicked the back button'),
                        'error',
                    )
            }
        } else {
            global.data = json
            logProviderResPlausibleEvents(global.data, params)
        }
    }

    async function processWelcome() {
        global.data = await getProfile(params.toString())
        //app info for welcome page and plausible events
        const welcome_app_info = {
            name: params.get('name'),
            image_uri: params.get('image_uri'),
            dark_image_uri: params.get('dark_image_uri'),
        }
        sessionStorage.setItem(
            'welcome_app_info',
            JSON.stringify(welcome_app_info),
        )
    }

    async function handleNoProfileState() {
        global.data = await getProfile()
    }

    function handleDropdown(toggledDropdown) {
        for (const i in dropdown) {
            if (i === toggledDropdown) {
                dropdown[i] = !dropdown[i]
                continue
            }
            dropdown[i] = false
        }
    }

    function showLogin() {
        clearLocalAndSessionStorage()
        return replaceRoute('/login')
    }

    async function toggleEdit() {
        keepAlive()
        //FIX
        handleDropdown() //closes all dropdown states
        verifyProvider = verifyProviderAuthority

        if (editMode) {
            editMode = false
            sessionStorage.removeItem('isInEditMode')
        } else {
            logPlausibleEvent({ u: '/edit', n: 'action' })
            editMode = true
            sessionStorage.setItem('isInEditMode', true)
        }
    }

    async function makePreferred(account) {
        await handlePutAuthority(
            account,
            { preferred: account.id },
            makeAuthorityNotification(
                account,
                '{provider} {label} is now preferred',
            ),
        )
    }

    async function makeRecovery(account) {
        await handlePutAuthority(
            account,
            { recovery: account.id },
            makeAuthorityNotification(
                account,
                '{provider} {label} is now a recovery',
            ),
        )
    }

    async function removeRecovery(account) {
        await handlePutAuthority(
            account,
            { no_recovery: account.id },
            makeAuthorityNotification(
                account,
                '{provider} {label} has been removed as a recovery',
            ),
        )
    }

    async function handlePutAuthority(account, action, notificationText) {
        const isAccountSocial = !['ethereum', 'email', 'phone'].includes(
            account.slug,
        )
        const res = await putAuthority(action, {
            redirectPathParam: isAccountSocial, // only send redirect_path param if social provider
            server: account.mastodonServer || null, // only send server param if mastodon
        })
        if (
            res?.error?.message ===
            'CANNOT_HAVE_MORE_THAN_ONE_RECOVERY_FROM_SAME_ORG'
        ) {
            notification.show(
                $_(
                    'Cannot have more than one recovery from the same organization',
                ),
                'error',
            )
            return
        } else if (res.verify) {
            verifyProviderAuthority = { id: account.id, ...res.verify, action }
            if (verifyProviderAuthority.slug === 'ethereum') {
                verifyProviderAuthority.wallet =
                    global.data.profile?.accounts?.find(
                        (i) => i.id === verifyProviderAuthority.id,
                    ).wallet
            } else if (verifyProviderAuthority.slug === 'mastodon') {
                //Do not show Mastodon dropdown UI - show continue with Mastodon account button
                verifyProviderAuthority.mastodonServer =
                    global.data.profile?.accounts?.find(
                        (i) => i.id === verifyProviderAuthority.id,
                    ).mastodonServer
                verifyProviderAuthority.user_name =
                    global.data.profile?.accounts?.find(
                        (i) => i.id === verifyProviderAuthority.id,
                    ).user_name
            }
            return
        } else if (res.accounts) {
            global.data.profile.accounts = res.accounts
            global.data = updateAccounts(global.data) //add sortrank, sortlabel and unverified accounts to accounts array
            notification.show(notificationText, 'success')
            return
        }
        throw new Error('unhandled put authority response body')
    }

    async function continueWithProvider(account, attribute, server) {
        global.spinner = true
        const { redirect } = await postLinkProvider({
            slug: account.slug,
            attribute,
            server,
        })
        window.location.href = redirect
    }

    function closeLandingModal() {
        showProfileLandingModal = false
        localStorage.removeItem('showProfileLandingModal')
        //Wait for modal to be popped
        setTimeout(() => {
            notification.show(
                `<span>${$_(
                    'Learn more about Hellō at',
                )} <a href="https://www.hello.coop" target="_blank" class="underline">hello.coop</a></span>`,
                'success',
            )
        }, 100)
    }
</script>

{#if remoteAuthDone}
    {#if $notification.text}
        <Notification />
    {/if}
    <div class="container mx-auto mt-12 max-w-md px-3 text-center">
        {$_('You can now close this window')}
    </div>
{/if}

{#if Object.keys(global.data).length && global.data.isPersonalLoggedIn && global.data.actions?.doneWizardAt}
    {#if showProfileLandingModal}
        <ProfileLandingModal close={closeLandingModal} />
    {/if}

    {#if !remoteAuthDone}
        <TitleBar profile {editMode} />
    {/if}

    {#if $notification.text}
        <Notification />
    {/if}

    {#if !global.spinner}
        <InfoSection {editMode} {toggleEdit} />

        <main class="flex-1 overflow-y-auto pb-16">
            <div class="container mx-auto max-w-2xl px-3">
                <PictureSection
                    {editMode}
                    bind:expanded={dropdown.picture}
                    {continueWithProvider}
                    {handleDropdown}
                />

                <LanguageSection
                    {editMode}
                    bind:expanded={dropdown.language}
                    {handleDropdown}
                />

                {#if editMode}
                    <NameSection />
                {/if}

                <ProviderSection
                    {editMode}
                    {handleDropdown}
                    {continueWithProvider}
                    {makePreferred}
                    {makeRecovery}
                    {removeRecovery}
                    bind:dropdownStates={dropdown}
                    bind:verifyProvider
                    bind:verifyProviderAuthority
                />

                <ApplicationSection {editMode} />

                <DeviceSection {editMode} />

                <DownloadDataSection {editMode} />
            </div>
        </main>
        <wc-footer use:setAttributes></wc-footer>
    {/if}
{/if}
