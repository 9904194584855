<script>
    import { _ } from 'svelte-i18n'
    import { onMount } from 'svelte'
    import {
        push as navigateTo,
        replace as replaceRoute,
    } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import {
        getProfile,
        postVerifyEmail,
        postVerifyEmailCode,
    } from '$utils/api.js'
    import { dedupe, setAttributes } from '$utils/common.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import Modal from '$lib/modal/Modal.svelte'
    import VerifyOTP from '$lib/VerifyOTP.svelte'
    import Notification from '$lib/Notification.svelte'
    import TitleBar from '$lib/TitleBar.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'

    let showOTPModal = $state(false)
    let ajaxRequest = $state(false)
    let managed = $state({})

    let ajaxRequestSendOTP = $state(false)
    let ajaxRequestResendOTP = $state(false)
    let otp = $state('')

    let selectedManagedEmail = $state()

    onMount(async () => {
        global.spinner = true

        //we dont have profile data
        if (!global.data?.version) {
            global.data = await getProfile()
        }

        //we already verified
        if (!global.data?.verifyManagedEmail) return replaceRoute('/')

        managed = global.data?.verifyManagedEmail

        if (managed)
            selectedManagedEmail = global.data?.verifyManagedEmail.emails[0]

        logPlausibleEvent({ u: '/wizard/email' })

        global.spinner = false
    })

    async function verifyEmail(resend = false) {
        ajaxRequest = true
        if (resend) {
            await postVerifyEmail(selectedManagedEmail, true)
        } else {
            await postVerifyEmail(selectedManagedEmail)
        }

        showOTPModal = selectedManagedEmail
        if (resend) otp = ''
        ajaxRequest = ajaxRequestResendOTP = false
    }

    async function verifyEmailCode(code) {
        ajaxRequestSendOTP = true
        const json = await postVerifyEmailCode(code)
        if (json.error) {
            otp = ''
            ajaxRequestSendOTP = false
        } else {
            global.data = await getProfile()
            notification.clear()
            if (!global.data?.merge) {
                navigateTo('/')
            }
            ajaxRequestSendOTP = false
        }
    }
</script>

<TitleBar showMenu={false} />

{#if $notification.text}
    <Notification />
{/if}

{#if !global.spinner}
    <div class="flex-1 overflow-y-auto">
        <div class="px-outer mx-auto w-full max-w-[856px]">
            <main class="gap-x-col flex flex-col items-center pb-12 pt-6">
                <div class="max-w-col px-indent mt-2 w-full">
                    <h1 class="text-lg">
                        {$_('Please verify the email to be used with')}
                    </h1>
                    <h1 data-test="domain" class="px-4 text-lg font-semibold">
                        {managed?.domain}
                    </h1>

                    <div class="my-6 space-y-5">
                        {#if Array.isArray(managed.emails) && [...new Set(managed.emails)].length > 1}
                            <div
                                class="bg-charcoal mt-2 space-y-2 rounded-md bg-opacity-10 p-2 dark:bg-opacity-100"
                            >
                                <div
                                    data-test="emails-container"
                                    class="relative flex items-center"
                                >
                                    <div class="w-full">
                                        <ul
                                            class="mt-1 flex flex-col items-start space-y-2"
                                        >
                                            {#each dedupe(managed?.emails) as email, index (email)}
                                                <li
                                                    data-test="email-{index}"
                                                    class="relative flex w-full items-center"
                                                >
                                                    <input
                                                        id="email-{email}"
                                                        name="email"
                                                        type="radio"
                                                        bind:group={
                                                            selectedManagedEmail
                                                        }
                                                        checked={index === 0}
                                                        value={email}
                                                        class="form-radio text-charcoal h-4 w-4 rounded-full dark:text-[#808080]"
                                                    />
                                                    <label
                                                        for="email-{email}"
                                                        class="ml-2.5 inline-flex w-full items-center truncate"
                                                    >
                                                        <span class="truncate">
                                                            {email}
                                                        </span>
                                                    </label>

                                                    {#if showOTPModal === email}
                                                        <Modal
                                                            position="center"
                                                            css="top-8"
                                                            close={() => {
                                                                showOTPModal = false
                                                                otp = ''
                                                            }}
                                                        >
                                                            <VerifyOTP
                                                                bind:otp
                                                                bind:ajaxRequestSendOTP
                                                                bind:ajaxRequestResendOTP
                                                                verifyCode={verifyEmailCode}
                                                                resend={() =>
                                                                    verifyEmail(
                                                                        true,
                                                                    )}
                                                            />
                                                        </Modal>
                                                    {/if}
                                                </li>
                                            {/each}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        {:else}
                            <div class="relative">
                                <div
                                    class="bg-charcoal block flex h-12 items-center overflow-hidden rounded-md bg-opacity-10 px-4 dark:bg-opacity-100"
                                >
                                    {selectedManagedEmail}
                                </div>
                                {#if showOTPModal === selectedManagedEmail}
                                    <Modal
                                        position="center"
                                        css="top-10"
                                        close={() => {
                                            showOTPModal = false
                                            otp = ''
                                        }}
                                    >
                                        <VerifyOTP
                                            bind:otp
                                            bind:ajaxRequestSendOTP
                                            bind:ajaxRequestResendOTP
                                            verifyCode={verifyEmailCode}
                                            resend={() => verifyEmail(true)}
                                        />
                                    </Modal>
                                {/if}
                            </div>
                        {/if}

                        <button
                            data-test="email-send-verification-btn"
                            onclick={() => verifyEmail(false)}
                            disabled={ajaxRequest}
                            class="btn-background relative inline-flex h-12 w-full items-center justify-center transition disabled:opacity-60"
                        >
                            {#if ajaxRequest}
                                <SpinnerIcon css="h-5 w-5 text-white" />
                            {:else}
                                {$_('Send verification code')}
                            {/if}
                        </button>
                    </div>
                </div>
            </main>
        </div>
    </div>

    <wc-footer use:setAttributes></wc-footer>
{/if}
