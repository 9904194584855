<script>
    import { deleteCookies } from '$utils/api.js'
    import { global } from '$src/state.svelte.js'
    import FullPageModal from '$src/lib/modal/FullPageModal.svelte'

    const reload = () => (window.location.href = window.location.origin + '/')

    async function startOver() {
        try {
            await deleteCookies()
        } catch (err) {
            console.error(err)
        }
        reload()
    }
</script>

<FullPageModal
    dataTest="unrecoverable-error-modal"
    modalCss="sm:max-w-sm sm:!-mt-80"
>
    <div class="mx-auto w-full text-center">
        <h1 class="text-lg font-medium text-red-500">
            {#if global.serverErrorModal}
                The server got confused
            {:else}
                Something went wrong
            {/if}
        </h1>
        <p class="mt-2 text-sm opacity-80">
            We've been notified and are looking into it.
        </p>
        {#if global.serverErrorModal}
            <button
                onclick={startOver}
                class="btn-background mt-5 inline-flex w-full items-center justify-center space-x-2"
            >
                <span>Let's start over</span>
            </button>
        {:else}
            <button
                onclick={reload}
                class="btn-background mt-5 inline-flex w-full items-center justify-center space-x-2"
            >
                <span>Let's try that again</span>
            </button>
        {/if}
    </div>
</FullPageModal>
