<script>
    import { onMount, onDestroy } from 'svelte'
    import logins from '$svr/providers/animation.json'

    onMount(() => {
        interval = setInterval(() => {
            i++
        }, 750)
    })

    onDestroy(() => {
        clearInterval(interval)
    })

    let i = $state(0)
    let interval

    function inwards(node, params) {
        const { delay = 0, duration = 500, type = 'logo' } = params

        return {
            delay,
            duration: type === 'logo' ? duration : 4000,
            css: (t) =>
                `transform: rotateX(${90 - t * 90}deg)  translateZ(${-30 + t * 30}px)`,
        }
    }

    function outwards(node, params) {
        const { delay = 0, duration, type = 'logo' } = params

        return {
            delay,
            duration: type === 'logo' ? duration : 4000,
            css: (t) =>
                `transform: rotateX(${t * 90 - 90}deg)  translateZ(${t * 30 - 30}px)`,
        }
    }

    $effect(() => {
        if (logins.length && i >= logins.length) {
            i = 0
        }
    })
</script>

<div class="flex flex-col items-center">
    {#if logins.length}
        {#key i}
            <!-- mastodon-logo.png because mastodon.png (icon of mastodon elephant) already exists and may be used somewhere - don't want it to break -->
            <img
                src="https://cdn.hello.coop/images/{logins[i] === 'mastodon'
                    ? 'mastodon-logo'
                    : logins[i]}.png"
                alt={logins[i]}
                in:inwards
                out:outwards
                class="absolute h-12"
            />
        {/key}
    {/if}
</div>

<style>
    img {
        transform-style: preserve-3d;
    }
</style>
