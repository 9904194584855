<script>
    import dayjs from '$utils/day.js'
    import { slide } from 'svelte/transition'
    import { postPicture, deletePicture } from '$utils/api.js'
    import { SLIDE_ANIM_MS } from '$src/constants.js'
    import { _, locale } from 'svelte-i18n'
    import { global } from '$src/state.svelte.js'
    import { dedupe, getDisplay } from '$utils/common'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import Dropdown from '$lib/Dropdown.svelte'
    import logins from '$svr/providers/logins.json'
    import UploadIcon from '$lib/icon/UploadIcon.svelte'
    import RemoveIconButton from '$lib/button/RemoveIconButton.svelte'
    import Modal from '$lib/modal/Modal.svelte'
    import RemoveButton from '$lib/button/RemoveButton.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import HandlePictureModal from '$lib/modal/HandlePictureModal.svelte'

    let {
        expanded = $bindable(false),
        editMode = false,
        continueWithProvider = () => {},
        handleDropdown = () => {},
    } = $props()

    let confirmPictureDelete = $state()

    function tryRemove(value) {
        if (value.includes('default-picture.png')) {
            return
        }

        confirmPictureDelete = value
    }

    let removePictureAjax = $state(false)
    async function removePicture(index) {
        removePictureAjax = confirmPictureDelete
        const { pictures } = await await deletePicture(index)
        global.data.profile.pictures = pictures
        confirmPictureDelete = null
        removePictureAjax = null
    }

    let uploadedPicture = $state(null)
    let handlePictureAjax = $state(false)
    let uploadPictureAjax = $state(false)
    async function uploadPicture(picture) {
        handlePictureAjax = true
        const formDataObj = new FormData()
        formDataObj.append('file', picture)
        const { pictures } = await postPicture(formDataObj)
        global.data.profile.pictures = pictures
        expanded = false
        uploadPictureAjax = false
        handlePictureAjax = false
    }
</script>

{#if uploadedPicture}
    <HandlePictureModal
        imgSrc={uploadedPicture}
        bind:ajaxRequest={handlePictureAjax}
        cancel={() => (uploadedPicture = null)}
        upload={(img) => {
            uploadPicture(img)
            uploadedPicture = null
        }}
    />
{/if}

<section id="pictures" class="mt-3 py-2">
    <h2 class="text-lg font-medium">{$_('Pictures')}</h2>
    <ul class="mt-2 flex flex-col space-y-4">
        {#if global.data.profile.pictures?.length}
            {#each dedupe(global.data.profile.pictures, 'picture') as { label, picture, source, slug, createdAt, updatedAt }, index}
                <li class="relative flex flex-shrink-0 items-center">
                    <div class="inline-flex w-6 flex-shrink-0 items-center">
                        {#if editMode && source !== 'default'}
                            <RemoveIconButton
                                onclick={() => tryRemove(picture)}
                            />
                        {/if}
                    </div>
                    <div class="flex items-center gap-x-2">
                        <img
                            src={picture}
                            alt={source === 'default'
                                ? $_('Generic Hellō Profile Picture')
                                : source === 'upload'
                                  ? $_('Uploaded from device')
                                  : $_('From {provider}', {
                                        values: {
                                            provider: getDisplay(slug),
                                        },
                                    })}
                            class="hide-alt-text h-12 w-12 flex-shrink-0 rounded-full object-cover"
                            title={source === 'default'
                                ? $_('Generic Hellō Profile Picture')
                                : source === 'upload'
                                  ? $_('Uploaded from device')
                                  : $_('From {provider}', {
                                        values: {
                                            provider: getDisplay(slug),
                                        },
                                    })}
                        />
                        <span>
                            {#if source === 'default'}
                                {$_('Generic Hellō Profile Picture')}
                            {:else if source === 'upload'}
                                {#if Date.now() - Date.parse(createdAt) < 24 * 60 * 60 * 1000}
                                    {$_('Uploaded today')}
                                {:else}
                                    {$_('Uploaded {date}', {
                                        values: {
                                            date: dayjs(createdAt)
                                                .locale($locale)
                                                .fromNow(),
                                        },
                                    })}
                                {/if}
                            {:else}
                                {$_('From {provider}', {
                                    values: {
                                        provider: source || getDisplay(slug),
                                    },
                                })}

                                {#if ['gravatar', 'twitter', 'github', 'gitlab', 'instagram'].includes(slug) && label}
                                    ({label})
                                {/if}

                                {#if slug !== 'gravatar'}
                                    ({Date.now() -
                                        Date.parse(updatedAt || createdAt) <
                                    24 * 60 * 60 * 1000
                                        ? updatedAt
                                            ? $_('Updated today')
                                            : $_('Added today')
                                        : $_(
                                              updatedAt
                                                  ? 'Updated {date}'
                                                  : 'Added {date}',
                                              {
                                                  values: {
                                                      date: dayjs(
                                                          updatedAt ||
                                                              createdAt,
                                                      )
                                                          .locale($locale)
                                                          .fromNow(),
                                                  },
                                              },
                                          )})
                                {/if}
                            {/if}
                        </span>
                    </div>

                    {#if confirmPictureDelete && confirmPictureDelete === picture}
                        <Modal
                            position="left"
                            css="top-10 md:min-w-[22rem]"
                            close={() => (confirmPictureDelete = null)}
                        >
                            <div
                                class="flex flex-col items-center justify-between md:flex-row"
                            >
                                <span>{$_('Confirm Deletion')}</span>
                                <div
                                    class="mt-4 flex items-center gap-x-4 md:mt-0 md:space-x-2"
                                >
                                    <button
                                        onclick={() =>
                                            (confirmPictureDelete = null)}
                                        class="btn-border h-9 w-20 px-0 text-sm"
                                        >{$_('Cancel')}</button
                                    >
                                    <RemoveButton
                                        onclick={() => removePicture(index)}
                                        isLoading={removePictureAjax ===
                                            picture}
                                        value="Delete"
                                    />
                                </div>
                            </div>
                        </Modal>
                    {/if}
                </li>
            {/each}
        {/if}
    </ul>
</section>
{#if !editMode}
    <div
        class="relative flex items-center {$locale && $locale.startsWith('ar')
            ? 'mr-6'
            : 'ml-6'} p-0.5"
        transition:slide={{ duration: SLIDE_ANIM_MS }}
    >
        <Dropdown
            hasBackground={false}
            ariaLabel={global.data.profile.pictures.filter((i) => !i.default)
                .length
                ? $_('Add another profile picture')
                : $_('Add a profile picture')}
            dataTest="add-profile-picture-btn"
            {expanded}
            onclick={() => handleDropdown('picture')}
        >
            <div
                class="flex h-12 items-center px-4 text-left"
                aria-hidden="true"
            >
                {global.data.profile.pictures.filter((i) => !i.default).length
                    ? $_('Add another profile picture')
                    : $_('Add a profile picture')}
            </div>

            {#if expanded}
                <div
                    class="z-30 flex w-full flex-col space-y-2 rounded-md p-4 text-left"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <!-- Picture upload start -->
                    <button
                        onclick={() => {
                            uploadPictureAjax = true
                            document
                                .getElementById('picture-upload-ref')
                                .click()
                            setTimeout(() => {
                                uploadPictureAjax = false
                            }, 2022)
                            /**
                             * TODO
                             * This is a hack to prevent closing of picture dropdown,
                             * clickOutside is fired because of input file window?
                             */
                            setTimeout(() => {
                                expanded = true
                            }, 1)
                        }}
                        disabled={uploadPictureAjax}
                        class="btn-background relative flex w-full flex-shrink-0 items-center overflow-hidden disabled:opacity-60 {uploadPictureAjax
                            ? 'justify-center'
                            : 'justify-start'} px-4"
                    >
                        <div class="flex w-full items-center justify-center">
                            {#if uploadPictureAjax}
                                <SpinnerIcon
                                    css="h-5 w-5 text-white dark:text-[#d4d4d4]"
                                />
                            {:else}
                                <UploadIcon />
                                <span
                                    class="block text-left {$locale &&
                                    $locale.startsWith('ar')
                                        ? 'mr-4'
                                        : 'ml-4'}"
                                >
                                    {$_('Upload from device')}
                                </span>

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="flex-shrink-0 {$locale &&
                                    $locale.startsWith('ar')
                                        ? 'mr-auto rotate-90'
                                        : 'ml-auto -rotate-90'} h-4.5 group-hover:stroke-3 transform text-white opacity-80 dark:text-[#d4d4d4]"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            {/if}

                            <input
                                id="picture-upload-ref"
                                type="file"
                                accept="image/*"
                                name="logo"
                                hidden
                                onchange={(e) =>
                                    (uploadedPicture = URL.createObjectURL(
                                        e.target.files[0],
                                    ))}
                                oncancel={() => (uploadPictureAjax = false)}
                            />
                        </div>
                    </button>
                    <!-- Picture upload end -->

                    {#each logins.filter((i) => i.claims.picture) as provider}
                        <LoginProvider
                            prefix="Get picture from"
                            onclick={(e) =>
                                continueWithProvider(provider, 'picture', e)}
                            {provider}
                        />
                    {/each}
                </div>
            {/if}
        </Dropdown>
    </div>
{/if}
