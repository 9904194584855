<script>
    import { push } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { _ } from 'svelte-i18n'
    import {
        getProfile,
        postLoginEth,
        postLoginEthChallenge,
        postLoginProvider,
    } from '$utils/api.js'
    import { trimEthAddress, getWallet } from '$utils/ethereum.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import { handleConsentResponse } from '$utils/consent.js'
    import RecommendedProviders from '$lib/login/RecommendedProviders.svelte'
    import RecoveryStatus from '$lib/login/RecoveryStatus.svelte'
    import LoginPreferred from '$lib/login/LoginPreferred.svelte'
    import LoginManaged from '$lib/login/LoginManaged.svelte'
    import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte'

    let ethereumProgressModal = $state()
    let ethereumProgressNotifs = $state([])

    const recoveryIds = $derived(global.data?.recovery?.map((i) => i.id) || [])
    const recoverable = $derived(
        global.data?.preferred?.length && recoveryIds.length >= 2,
    )
    const loggedInSubject = $derived(
        global.data?.recovery?.find((i) => i.id === global.data?.subjects[0]) ||
            {},
    )
    const isRecoveryMode = $derived(
        !global.data?.isPersonalLoggedIn &&
            !!global.data?.subjects?.find((subject) =>
                recoveryIds.includes(subject),
            ),
    )
    const isManagedRecoveryMode = $derived(
        !global.data?.isPersonalLoggedIn && global.data?.isManagedLoggedIn,
    )
    const hasPreferred = $derived(global.data?.preferred?.length)
    const hasManaged = $derived(global.data?.managed?.length)
    const isManagedLoggedIn = $derived(global.data?.isManagedLoggedIn)

    async function contactLoginSuccess(e) {
        const res = await contactLogin(e)
        if (!res?.uri && !res?.response_mode) {
            push('/')
        }
    }

    async function contactLogin() {
        global.data = await getProfile()
    }

    async function continueWithEthereumExtension({ info, account } = {}) {
        const [address] = await window.ethereum.request({
            method: 'eth_requestAccounts',
        })
        ethereumProgressModal = 'extension'
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                text: $_('Wallet Connected ({address})', {
                    values: {
                        address: trimEthAddress(address),
                    },
                }),
                type: 'success',
                status: $_('Waiting to sign'),
            },
        ]
        if (
            info?.login_hint &&
            info?.login_hint.toLowerCase() !== address.toLowerCase()
        ) {
            ethereumProgressNotifs = [
                ...ethereumProgressNotifs,
                {
                    text: $_('Expected ({address})', {
                        values: {
                            address: trimEthAddress(info.login_hint),
                        },
                    }),
                    type: 'error',
                    status: address,
                },
            ]
            return
        }
        continueEthExtensionSigning(address, account)
    }

    async function continueEthExtensionSigning(address, account) {
        let challenge, signature
        const slug = getWallet().slug
        const res = await postLoginEth(address)
        logPlausibleEvent({
            u: `/start/login/ethereum/extension/${slug}`,
            n: 'action',
        })
        challenge = res.challenge
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                status: $_('Waiting to sign'),
            },
        ]

        try {
            signature = await window.ethereum.request({
                method: 'personal_sign',
                params: [address, challenge],
            })
            ethereumProgressNotifs = [
                ...ethereumProgressNotifs,
                {
                    text: $_('Message signed'),
                    type: 'success',
                    status: $_('Logging you in'),
                },
            ]
        } catch (err) {
            console.info(err)
            if (err.code === 4001) {
                notification.show(
                    $_("You've rejected the sign request"),
                    'error',
                )
            } else {
                notification.show(
                    $_('Something went wrong. Please try again later.'),
                    'error',
                )
            }
            ethereumProgressModal = null
            ethereumProgressNotifs = []
            return
        }

        const body = {
            signature,
            address,
            icon: getWallet().icon,
            name: getWallet().name,
            account,
        }

        await postLoginEthChallenge(body)
        await logPlausibleEvent({
            u: `/login/ethereum/extension/${getWallet().slug}`,
            n: 'action',
        })
        global.data = await getProfile()
        if (!res?.uri && !res?.response_mode) {
            push('/')
        } else {
            global.spinner = true
            handleConsentResponse(res)
        }
    }

    async function continueWithProvider({ slug, body, server } = {}) {
        global.spinner = true
        const { redirect } = await postLoginProvider({
            slug,
            body,
            server,
        })
        window.location.href = redirect
    }

    async function handleManagedEmailSuccess() {
        global.data = await getProfile()
        return push('/')
    }
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
    <EthereumProgressModal
        notifications={ethereumProgressNotifs}
        cancel={() => {
            ethereumProgressNotifs = []
            ethereumProgressModal = null
        }}
        ok={(e) => {
            if (ethereumProgressModal === 'extension') {
                continueEthExtensionSigning(e)
            }
        }}
    />
{/if}

{#if !hasPreferred && hasManaged && !isManagedLoggedIn}
    <LoginManaged
        heading={$_('Your Hellō Wallet requires a personal account')}
        label={$_('To set one up:<br/>Log in with your managed account')}
        {continueWithProvider}
        {contactLoginSuccess}
        accounts={global.data?.managed}
    />
{:else}
    {#if isRecoveryMode}
        <RecoveryStatus
            {continueWithProvider}
            {contactLoginSuccess}
            {continueWithEthereumExtension}
            {loggedInSubject}
        />
    {/if}

    {#if !isRecoveryMode || (isManagedRecoveryMode && !recoverable)}
        <h1
            class="relative mb-6 flex items-center justify-center text-lg font-medium"
        >
            {#if !hasPreferred && hasManaged}
                {$_('Your Hellō Wallet requires a personal account')}
            {:else}
                {$_('Log in to view and manage your Hellō Wallet')}
            {/if}
        </h1>

        {#if hasPreferred}
            <LoginPreferred
                accounts={global.data.preferred}
                {contactLoginSuccess}
                {continueWithEthereumExtension}
                {continueWithProvider}
            />
        {:else}
            <RecommendedProviders
                showManagedLogin={false}
                {contactLoginSuccess}
                {continueWithEthereumExtension}
                {continueWithProvider}
                {handleManagedEmailSuccess}
            />
        {/if}
    {/if}
{/if}
