<script>
    import { _, locale } from 'svelte-i18n'
    import { slide, fade } from 'svelte/transition'
    import {
        push as navigateTo,
        replace as replaceRoute,
    } from 'svelte-spa-router'
    import TitleBar from '$lib/TitleBar.svelte'
    import VerifyEmail from '$lib/VerifyEmail.svelte'
    import VerifyPhone from '$lib/VerifyPhone.svelte'
    import MailIcon from '$lib/icon/MailIcon.svelte'
    import PhoneIcon from '$lib/icon/PhoneIcon.svelte'
    import PointerIcon from '$lib/icon/PointerIcon.svelte'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import WizardProgress from '$lib/WizardProgress.svelte'
    import Dropdown from '$lib/Dropdown.svelte'
    import logins from '$svr/providers/logins.json'
    import {
        getProfile,
        keepAlive,
        postLinkEth,
        postLinkEthChallenge,
        postLinkProvider,
    } from '$utils/api.js'
    import { getWallet, trimEthAddress } from '$utils/ethereum.js'
    import { clearLocalAndSessionStorage } from '$utils/storage.js'
    import {
        FADE_ANIM_DELAY_MS,
        SLIDE_ANIM_MS,
        WIZARD_FUNNEL,
    } from '$src/constants.js'
    import LoginProviderGroup from '$lib/LoginProviderGroup.svelte'
    import { onMount } from 'svelte'
    import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte'
    import Notification from '$lib/Notification.svelte'
    import { setAttributes } from '$utils/common.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import LoginProvider from '$lib/LoginProvider.svelte'

    let continueWithEmail = $state(false)
    let continueWithPhone = $state(false)

    let ethereumProgressModal = $state(null)
    let ethereumProgressNotifs = $state([])

    onMount(async () => {
        global.spinner = true

        //we dont have profile data
        if (!global.data?.version) {
            global.data = await getProfile()
            if (
                !global.data.isPersonalLoggedIn &&
                !global.data?.isManagedLoggedIn
            ) {
                clearLocalAndSessionStorage()
                return replaceRoute('/login')
            }
            if (global.data.actions?.doneWizardAt) return replaceRoute('/')
        }

        if (!global.data?.preferred?.length)
            return replaceRoute('/wizard/preferredprovider')

        nextPage()

        localStorage.setItem('currentWizardStage', 'recoveryprovider')
        logWizardFunnelPlausibleEvent()
        logPlausibleEvent({ u: '/wizard/recoveryprovider' })
        global.spinner = false
    })

    function logWizardFunnelPlausibleEvent() {
        //Wizard Funnel
        const indexOfCurrentFunnelStep = WIZARD_FUNNEL.indexOf(
            sessionStorage.wiz_funnel,
        )
        const indexOfNextFunnelStep = WIZARD_FUNNEL.indexOf('wiz_recovery')
        //wizard funnel state is valid and not already sent
        if (
            indexOfCurrentFunnelStep !== -1 &&
            indexOfNextFunnelStep > indexOfCurrentFunnelStep
        ) {
            const preferred = global.data?.preferred?.[0]?.slug
            let welcome_email_app
            if (sessionStorage.welcome_app_info) {
                if (sessionStorage.welcome_app_info) {
                    try {
                        welcome_email_app = JSON.parse(
                            sessionStorage.welcome_app_info,
                        )?.name
                    } catch (err) {
                        console.error(err)
                    }
                }
            }
            logPlausibleEvent({
                n: 'Wiz Recovery',
                p: { preferred, welcome_email_app },
                u: '/wizard/recoveryprovider',
            })
            sessionStorage.setItem('wiz_funnel', 'wiz_recovery')
        }
    }

    function nextPage() {
        const preferred = global.data.profile?.accounts?.find(
            (i) => i.preferred,
        )
        const nonSocialProviders = ['email', 'phone']
        const isPreferredSocial = !nonSocialProviders.includes(preferred?.slug)
        const socialAccountLinked = global.data.profile?.accounts.filter(
            (i) =>
                ![...nonSocialProviders, 'ethereum'].includes(i.slug) &&
                !i.managed,
        ).length
        const recoveries = global.data.profile?.accounts.filter(
            (i) => i.recovery && !i.preferred && !i.communal,
        )

        if (recoveries?.length >= 2) {
            if (!isPreferredSocial && socialAccountLinked) {
                return navigateTo('/wizard/upgrade')
            } else {
                return navigateTo('/wizard/status')
            }
        }
    }

    async function verifyEmailSuccess() {
        //Wizard Funnel
        if (sessionStorage.wiz_funnel) {
            const preferred = global.data?.preferred?.[0]?.slug
            let welcome_email_app
            if (sessionStorage.welcome_app_info) {
                if (sessionStorage.welcome_app_info) {
                    try {
                        welcome_email_app = JSON.parse(
                            sessionStorage.welcome_app_info,
                        )?.name
                    } catch (err) {
                        console.error(err)
                    }
                }
            }
            const recovery_1 = global.data?.recovery?.[0]?.slug
            if (sessionStorage.wiz_funnel === 'wiz_recovery_1_start') {
                logPlausibleEvent({
                    n: 'Wiz Recovery 1 Success',
                    p: { preferred, welcome_email_app, recovery_1: 'email' },
                    u: '/',
                })
                sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_success')
            } else if (sessionStorage.wiz_funnel === 'wiz_recovery_2_start') {
                logPlausibleEvent({
                    n: 'Wiz Recovery 2 Success',
                    p: {
                        preferred,
                        welcome_email_app,
                        recovery_1,
                        recovery_2: 'email',
                    },
                    u: '/',
                })
                sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_success')
            }
        }

        continueWithEmail = false
        global.data = await getProfile()
        nextPage()
    }

    async function verifyPhoneSuccess() {
        //Wizard Funnel
        if (sessionStorage.wiz_funnel) {
            const preferred = global.data?.preferred?.[0]?.slug
            let welcome_email_app
            if (sessionStorage.welcome_app_info) {
                if (sessionStorage.welcome_app_info) {
                    try {
                        welcome_email_app = JSON.parse(
                            sessionStorage.welcome_app_info,
                        )?.name
                    } catch (err) {
                        console.error(err)
                    }
                }
            }
            const recovery_1 = global.data?.recovery?.[0]?.slug
            if (sessionStorage.wiz_funnel === 'wiz_recovery_1_start') {
                logPlausibleEvent({
                    n: 'Wiz Recovery 1 Success',
                    p: { preferred, welcome_email_app, recovery_1: 'email' },
                    u: '/',
                })
                sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_success')
            } else if (sessionStorage.wiz_funnel === 'wiz_recovery_2_start') {
                logPlausibleEvent({
                    n: 'Wiz Recovery 2 Success',
                    p: {
                        preferred,
                        welcome_email_app,
                        recovery_1,
                        recovery_2: 'email',
                    },
                    u: '/',
                })
                sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_success')
            }
        }
        continueWithPhone = false
        global.data = await getProfile()
        nextPage()
    }

    async function continueWithEthereumExtension() {
        const [address] = await window.ethereum.request({
            method: 'eth_requestAccounts',
        })
        ethereumProgressModal = 'extension'
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                text: $_('Wallet Connected ({address})', {
                    values: {
                        address: trimEthAddress(address),
                    },
                }),
                type: 'success',
                status: $_('Waiting to sign'),
            },
        ]
        continueEthExtensionSigning(address)
    }

    async function continueEthExtensionSigning(address) {
        let challenge, signature
        const slug = getWallet().slug
        const res = await postLinkEth(address)
        logPlausibleEvent({
            u: `/start/link/ethereum/extension/${slug}`,
            n: 'action',
        })
        challenge = res.challenge
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                status: $_('Waiting to sign'),
            },
        ]

        //Wizard Funnel
        if (sessionStorage.wiz_funnel) {
            const preferred = global.data?.preferred?.[0]?.slug
            let welcome_email_app
            if (sessionStorage.welcome_app_info) {
                if (sessionStorage.welcome_app_info) {
                    try {
                        welcome_email_app = JSON.parse(
                            sessionStorage.welcome_app_info,
                        )?.name
                    } catch (err) {
                        console.error(err)
                    }
                }
            }
            const recovery_1 = global.data?.recovery?.[0]?.slug
            if (!recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery') {
                await logPlausibleEvent({
                    n: 'Wiz Recovery 1 Start',
                    p: { preferred, welcome_email_app, recovery_1: slug },
                    u: '/wizard/recoveryprovider',
                })
                sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_start')
            } else if (
                recovery_1 &&
                sessionStorage.wiz_funnel === 'wiz_recovery_1_success'
            ) {
                await logPlausibleEvent({
                    n: 'Wiz Recovery 2 Start',
                    p: {
                        preferred,
                        welcome_email_app,
                        recovery_1,
                        recovery_2: slug,
                    },
                    u: '/wizard/recoveryprovider',
                })
                sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_start')
            }
        }

        try {
            signature = await window.ethereum.request({
                method: 'personal_sign',
                params: [address, challenge],
            })
            ethereumProgressNotifs = [
                ...ethereumProgressNotifs,
                {
                    text: $_('Message signed'),
                    type: 'success',
                    status: $_('Linking wallet'),
                },
            ]
        } catch (err) {
            console.info(err)
            if (err.code === 4001) {
                notification.show(
                    $_(`You've rejected the sign request`),
                    'error',
                )
            } else {
                notification.show(
                    $_('Something went wrong. Please try again later.'),
                    'error',
                )
            }
            ethereumProgressModal = null
            ethereumProgressNotifs = []
            return
        }

        const body = {
            signature,
            address,
            icon: getWallet().icon,
            name: getWallet().name,
        }

        await postLinkEthChallenge(body)
        notification.show(
            $_('{provider} {label} has been added', {
                values: {
                    provider: body.name,
                    label: trimEthAddress(address),
                },
            }),
            'success',
        )
        logPlausibleEvent({
            u: `/link/ethereum/extension/${slug}`,
            n: 'action',
        })
        global.data = await getProfile()

        //Wizard Funnel
        const preferred = global.data?.preferred?.[0]?.slug
        let welcome_email_app
        if (sessionStorage.welcome_app_info) {
            if (sessionStorage.welcome_app_info) {
                try {
                    welcome_email_app = JSON.parse(
                        sessionStorage.welcome_app_info,
                    )?.name
                } catch (err) {
                    console.error(err)
                }
            }
        }
        const recovery_1 = global.data?.recovery?.[0]?.slug
        if (sessionStorage.wiz_funnel === 'wiz_recovery_1_start') {
            logPlausibleEvent({
                n: 'Wiz Recovery 1 Success',
                p: { preferred, welcome_email_app, recovery_1: slug },
                u: '/',
            })
            sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_success')
        } else if (sessionStorage.wiz_funnel === 'wiz_recovery_2_start') {
            logPlausibleEvent({
                n: 'Wiz Recovery 2 Success',
                p: {
                    preferred,
                    welcome_email_app,
                    recovery_1,
                    recovery_2: slug,
                },
                u: '/',
            })
            sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_success')
        }
        ethereumProgressModal = null
        ethereumProgressNotifs = []
        nextPage()
    }

    function skip() {
        keepAlive()
        const preferred = global.data.profile?.accounts?.find(
            (i) => i.preferred,
        )
        const nonSocialProviders = ['email', 'phone']
        const isPreferredSocial = !nonSocialProviders.includes(preferred?.slug)
        const socialAccountLinked = global.data.profile?.accounts.filter(
            (i) =>
                ![...nonSocialProviders, 'ethereum'].includes(i.slug) &&
                !i.managed,
        ).length
        const recoveries = global.data.profile?.accounts.filter(
            (i) => i.recovery && !i.preferred && !i.communal,
        )

        if (!isPreferredSocial && socialAccountLinked) {
            return navigateTo('/wizard/upgrade')
        } else if (recoveries.length < 2) {
            return navigateTo('/wizard/incomplete')
        } else {
            return navigateTo('/wizard/status')
        }
    }

    async function continueWithProvider(slug, server) {
        global.spinner = true
        const { redirect } = await postLinkProvider({
            slug,
            attribute: 'email',
            server,
        })
        window.location.href = redirect
    }
</script>

<TitleBar />

{#if $notification.text}
    <Notification />
{/if}

{#if ethereumProgressModal && ethereumProgressNotifs.length}
    <EthereumProgressModal
        notifications={ethereumProgressNotifs}
        cancel={() => {
            ethereumProgressNotifs = []
            ethereumProgressModal = null
        }}
        ok={(e) => {
            if (ethereumProgressModal === 'extension') {
                continueEthExtensionSigning(e)
            }
        }}
    />
{/if}

{#if global.data?.isPersonalLoggedIn && !global.spinner}
    <main class="flex-1 overflow-y-auto px-3 pb-16 md:px-0">
        <WizardProgress compact />

        <div>
            <h1
                class="mb-2 block border-none text-center text-base font-medium md:hidden md:text-2xl"
                in:fade|global={{ delay: 4 * FADE_ANIM_DELAY_MS }}
            >
                {#if global.data.profile?.accounts?.find((i) => i.recovery && !i.preferred)}
                    {$_('Add second recovery provider')}
                {:else}
                    {$_('Add first recovery provider')}
                {/if}
            </h1>
            <p
                class="mx-auto text-center text-xs italic md:hidden md:text-sm"
                in:fade|global={{ delay: 4 * FADE_ANIM_DELAY_MS }}
            >
                {$_('A recovery provider could be shared with someone else')}
            </p>
            <div
                class="mx-auto mt-6 flex max-w-md items-center justify-between text-sm md:max-w-2xl"
            >
                <button
                    data-test="back-btn"
                    onclick={() => {
                        keepAlive()
                        navigateTo('/wizard/status')
                    }}
                    class="group invisible relative inline-flex w-20 items-center justify-center {$locale &&
                    $locale.startsWith('ar')
                        ? 'mr-4'
                        : 'ml-4'}"
                    in:fade|global={{ delay: 4 * FADE_ANIM_DELAY_MS }}
                >
                    <span
                        class="absolute {$locale && !$locale.startsWith('ar')
                            ? 'ml-2'
                            : ''}">{$_('Back')}</span
                    >
                    <PointerIcon dir="left" />
                </button>
                <h1
                    class="hidden w-96 border-none text-center text-base font-medium md:block md:text-2xl"
                    in:fade|global={{ delay: 4 * FADE_ANIM_DELAY_MS }}
                >
                    {#if global.data.profile?.accounts?.find((i) => i.recovery && !i.preferred)}
                        {$_('Add second recovery provider')}
                    {:else}
                        {$_('Add first recovery provider')}
                    {/if}
                </h1>
                <button
                    data-test="skip-btn"
                    onclick={skip}
                    class="group relative inline-flex w-20 items-center justify-center {$locale &&
                    $locale.startsWith('ar')
                        ? 'ml-4'
                        : 'mr-4'}"
                    in:fade|global={{ delay: 4 * FADE_ANIM_DELAY_MS }}
                >
                    <span class="absolute mr-1">
                        {$_('Skip')}
                    </span>
                    <!-- Next arrow -->
                    <PointerIcon dir="right" />
                </button>
            </div>
            <div class="mx-auto mt-2 text-center md:max-w-2xl">
                <p
                    class="mx-auto hidden text-xs italic md:block md:w-3/4 md:text-sm"
                    in:fade|global={{ delay: 4 * FADE_ANIM_DELAY_MS }}
                >
                    {$_(
                        'A recovery provider could be shared with someone else',
                    )}
                </p>

                <section
                    class="mx-auto mt-6 max-w-md"
                    in:fade|global={{ delay: 5 * FADE_ANIM_DELAY_MS }}
                >
                    <LoginProviderGroup
                        prefix="Add"
                        ethereum={continueWithEthereumExtension}
                        managedEmailSuccess={verifyEmailSuccess}
                    >
                        <Dropdown
                            dataTest="add-email-btn"
                            expanded={continueWithEmail}
                            ariaLabel={global.data.profile?.accounts?.find(
                                (i) =>
                                    i.slug === 'email' &&
                                    i.recovery &&
                                    !i.managed,
                            )
                                ? $_('Add another email')
                                : $_('Add email')}
                            onclick={() => {
                                continueWithEmail = !continueWithEmail
                                continueWithPhone = false
                            }}
                        >
                            <div
                                class="flex h-12 w-full items-center justify-start gap-x-4 px-4"
                            >
                                <MailIcon />
                                <span
                                    class="block text-left"
                                    aria-hidden="true"
                                >
                                    {global.data.profile?.accounts?.find(
                                        (i) =>
                                            i.slug === 'email' &&
                                            i.recovery &&
                                            !i.managed,
                                    )
                                        ? $_('Add another email')
                                        : $_('Add email')}
                                </span>
                            </div>
                            {#if continueWithEmail}
                                <div
                                    class="px-3 pb-3 pt-1"
                                    transition:slide={{
                                        duration: SLIDE_ANIM_MS,
                                    }}
                                >
                                    <VerifyEmail success={verifyEmailSuccess} />
                                    <section class="mt-5 space-y-3">
                                        {#each logins.filter((i) => i.claims.verified_email) as provider}
                                            <LoginProvider
                                                onclick={(e) =>
                                                    continueWithProvider(
                                                        provider.slug,
                                                        e,
                                                    )}
                                                {provider}
                                                prefix="Get email from"
                                            />
                                        {/each}
                                    </section>
                                </div>
                            {/if}
                        </Dropdown>

                        <Dropdown
                            dataTest="add-phone-btn"
                            expanded={continueWithPhone}
                            ariaLabel={global.data.profile?.accounts?.find(
                                (i) =>
                                    i.slug === 'phone' &&
                                    i.recovery &&
                                    !i.managed,
                            )
                                ? $_('Add another phone')
                                : $_('Add phone')}
                            onclick={() => {
                                continueWithPhone = !continueWithPhone
                                continueWithEmail = false
                            }}
                        >
                            <div
                                class="flex h-12 w-full items-center justify-start gap-x-4 px-4"
                            >
                                <PhoneIcon />
                                <span
                                    class="block text-left"
                                    aria-hidden="true"
                                >
                                    {global.data.profile?.accounts?.find(
                                        (i) =>
                                            i.slug === 'phone' &&
                                            i.recovery &&
                                            !i.managed,
                                    )
                                        ? $_('Add another phone')
                                        : $_('Add phone')}
                                </span>
                            </div>

                            {#if continueWithPhone}
                                <div
                                    class="px-3 pb-3 pt-1"
                                    transition:slide={{
                                        duration: SLIDE_ANIM_MS,
                                    }}
                                >
                                    <VerifyPhone success={verifyPhoneSuccess} />
                                </div>
                            {/if}
                        </Dropdown>
                    </LoginProviderGroup>
                </section>
            </div>
        </div>
    </main>

    <wc-footer use:setAttributes></wc-footer>
{/if}
