import { mount } from 'svelte'
import Index from './pages/Index.svelte'
import './index.css'
import './i18n.js'
import 'intl-tel-input/build/js/utils.js'

// act as const referenced globally
window.isWalletApp = () => true
window.isWalletAuthorizeApp = () => false
window.isWalletInviteApp = () => false

const app = mount(Index, { target: document.body })

export default app
