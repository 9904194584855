<script>
    import { onMount } from 'svelte'
    import { fade } from 'svelte/transition'
    import { _ } from 'svelte-i18n'
    import {
        push as navigateTo,
        replace as replaceRoute,
    } from 'svelte-spa-router'
    import TitleBar from '$lib/TitleBar.svelte'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { getProfile, keepAlive } from '$utils/api.js'
    import ProvidersAnimation from '$lib/animation/ProvidersAnimation.svelte'
    import Notification from '$lib/Notification.svelte'
    import { setAttributes } from '$utils/common.js'
    import { clearLocalAndSessionStorage } from '$utils/storage.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import { deviceTheme } from '$utils/device.js'
    import { FADE_ANIM_DELAY_MS } from '$src/constants.js'

    let welcomeAppInfo = $state({})
    onMount(async () => {
        global.spinner = true

        //we dont have profile data
        if (!global.data?.version) {
            global.data = await getProfile()
            if (
                !global.data.isPersonalLoggedIn &&
                !global.data?.isManagedLoggedIn
            ) {
                clearLocalAndSessionStorage()
                return replaceRoute('/login')
            }
        }

        localStorage.setItem('currentWizardStage', 'welcome')

        if (sessionStorage.welcome_app_info) {
            try {
                if (sessionStorage.welcome_app_info) {
                    try {
                        welcomeAppInfo = JSON.parse(
                            sessionStorage.welcome_app_info,
                        )?.name
                    } catch (err) {
                        console.error(err)
                    }
                }
            } catch (err) {
                console.error(err)
            }
        }

        logWizardFunnelPlausibleEvent()

        if (welcomeAppInfo.name) {
            //From welcome link
            logPlausibleEvent({ u: '/welcome' })
        } else {
            logPlausibleEvent({ u: '/wizard/welcome' })
        }

        global.spinner = false
    })

    function logWizardFunnelPlausibleEvent() {
        //Start of Wizard Funnel
        //wizard funnel state is not already sent + is in wizard
        const isInWizard = !global.data?.actions?.doneWizardAt //this flag is sent only when user completes wizard
        if (!sessionStorage.wiz_funnel && isInWizard) {
            const preferred = global.data?.preferred?.[0]?.slug
            let welcome_email_app
            if (sessionStorage.welcome_app_info) {
                try {
                    if (sessionStorage.welcome_app_info) {
                        try {
                            welcome_email_app = JSON.parse(
                                sessionStorage.welcome_app_info,
                            )?.name
                        } catch (err) {
                            console.error(err)
                        }
                    }
                } catch (err) {
                    console.error(err)
                }
            }
            logPlausibleEvent({
                n: 'Wiz Welcome',
                p: { preferred, welcome_email_app },
                u: '/wizard/welcome',
            })
            sessionStorage.setItem('wiz_funnel', 'wiz_welcome')
        }
    }

    function start() {
        keepAlive()
        const preferred = global.data.profile?.accounts?.find(
            (i) => i.preferred,
        )
        const nonSocialProviders = ['email', 'phone', 'ethereum']
        const isPreferredSocial = !nonSocialProviders.includes(preferred?.slug)
        const socialAccountLinked = global.data.profile?.accounts.filter(
            (i) => ![...nonSocialProviders, 'ethereum'].includes(i.slug),
        ).length
        const recoveries = global.data.profile?.accounts?.filter(
            (i) => i.recovery && !i.preferred,
        )
        if (global.data.actions?.doneWizardAt) {
            return navigateTo('/')
        } else if (
            recoveries?.length >= 2 &&
            !isPreferredSocial &&
            socialAccountLinked
        ) {
            return navigateTo('/wizard/upgrade')
        } else if (global.data?.verifyManagedEmail) {
            return navigateTo('/wizard/email')
        } else if (
            global.data?.chooseWhoManages ||
            global.data?.chooseManagedLogo
        ) {
            return navigateTo('/wizard/managed')
        } else if (!global.data?.preferred?.length) {
            return navigateTo('/wizard/preferredprovider')
        } else {
            return navigateTo('/wizard/status')
        }
    }

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            start()
        }
    }
</script>

<svelte:head>
    <title>Hellō - {$_('Welcome')}</title>
</svelte:head>

<svelte:window onkeydown={handleEnterKey} />

<TitleBar
    overrideTitle={$_('Welcome {name}', {
        values: {
            name:
                global.data.profile?.nicknames?.[0] ||
                global.data.profile?.given_names?.[0],
        },
    })}
/>

{#if $notification.text}
    <Notification />
{/if}

{#if !global.spinner}
    <main class="flex-1 overflow-y-auto pb-16">
        <div class="mt-8">
            <div
                class="mx-auto flex max-w-2xl items-center justify-between space-x-2 px-3 text-sm md:space-x-0"
                in:fade|global={{ delay: 0 * FADE_ANIM_DELAY_MS }}
            >
                <p class="flex-1 border-none text-center text-base md:text-xl">
                    {$_(
                        'You are now a member of Hellō, a free service that lets you log in with your preferred provider anywhere that accepts Hellō',
                    )}
                </p>
            </div>
            <div class="mx-auto mt-4 px-3 text-center md:max-w-2xl md:px-0">
                <div
                    class="invert-and-saturate relative mt-10 h-20"
                    in:fade|global={{ delay: 1 * FADE_ANIM_DELAY_MS }}
                >
                    <ProvidersAnimation />
                </div>

                <p
                    class="text-base md:text-xl"
                    in:fade|global={{ delay: 2 * FADE_ANIM_DELAY_MS }}
                >
                    {#if welcomeAppInfo?.name}
                        {$_(
                            'Hellō is a layer between applications, such as {appName}, and providers giving you control and privacy',
                            { values: { appName: welcomeAppInfo?.name } },
                        )}
                    {:else}
                        {$_(
                            'Hellō is a layer between applications and providers giving you control and privacy',
                        )}
                    {/if}
                </p>

                <div
                    class="relative h-20"
                    in:fade|global={{ delay: 3 * FADE_ANIM_DELAY_MS }}
                >
                    <div class="relative mx-auto my-8 w-3/4 md:w-1/2">
                        {#if deviceTheme === 'dark' && welcomeAppInfo?.dark_image_uri && welcomeAppInfo?.dark_image_uri != 'undefined'}
                            <div
                                class="app-image absolute z-50 resize md:left-1 md:top-5 md:h-10 md:w-[17%]"
                                style="background-image: url({welcomeAppInfo?.dark_image_uri}); background-size: contain; background-repeat: no-repeat; background-position: center;"
                            ></div>
                        {:else if welcomeAppInfo?.image_uri && welcomeAppInfo?.image_uri != 'undefined'}
                            <div
                                class="app-image absolute z-50 resize md:left-1 md:top-5 md:h-10 md:w-[17%]"
                                style="background-image: url({welcomeAppInfo?.image_uri}); background-size: contain; background-repeat: no-repeat; background-position: center;"
                            ></div>
                        {/if}
                        <img
                            src="https://cdn.hello.coop/images/landing-page-graphic.png"
                            alt="Hellō graphic"
                            class="invert-and-saturate w-full"
                        />
                    </div>
                </div>

                <p
                    class="mt-12 text-base md:text-xl"
                    in:fade|global={{ delay: 4 * FADE_ANIM_DELAY_MS }}
                >
                    {$_(
                        'For example, Google will only see you have logged into Hellō if you choose Google as your preferred provider',
                    )}
                </p>

                <h1
                    class="mt-10 flex-1 border-none text-center text-xl font-semibold md:text-2xl"
                    in:fade|global={{ delay: 5 * FADE_ANIM_DELAY_MS }}
                >
                    {#if global.data.actions?.doneWizardAt}
                        {$_('You have already set up your wallet')}
                    {:else if global.data.profile?.accounts?.filter((i) => !i.preferred && i.recovery).length >= 2}
                        {$_('You now have more control of your identity')}
                    {:else}
                        {$_("Let's set up wallet recovery")}
                    {/if}
                </h1>

                <button
                    onclick={start}
                    data-test="welcome-continue-btn"
                    class="btn-background mt-8 w-full max-w-md text-lg"
                    in:fade|global={{ delay: 6 * FADE_ANIM_DELAY_MS }}
                >
                    {#if global.data.actions?.doneWizardAt || global.data.profile?.accounts?.filter((i) => !i.preferred && i.recovery).length >= 2}
                        {$_('Continue')}
                    {:else}
                        {$_('Start')}
                    {/if}
                </button>
            </div>
        </div>
    </main>

    <wc-footer use:setAttributes></wc-footer>
{/if}

<style>
    @media (prefers-color-scheme: dark) {
        .invert-and-saturate {
            filter: invert(80%) saturate(0%);
        }

        .app-image {
            background-color: #404040;
        }
    }

    @media (prefers-color-scheme: light) {
        .invert-and-saturate {
            filter: saturate(0%);
        }

        .app-image {
            background-color: #eaeaea;
        }
    }

    @media only screen and (max-width: 768px) {
        .resize {
            width: 78px;
            left: 8px;
            height: 52px;
            top: 30px;
        }
    }

    @media only screen and (max-width: 640px) {
        .resize {
            width: 11.5vw;
            left: 1.5vw;
            height: 8.5vw;
            top: 4.2vw;
        }
    }
</style>
