<script>
    import { _ } from 'svelte-i18n'
    import FullPageModal from '$lib/modal/FullPageModal.svelte'

    let { close = () => {} } = $props()
</script>

<FullPageModal {close} dataTest="profile-landing-modal">
    <span class="mt-2 text-lg font-semibold"
        >{$_('This is your Hellō Wallet')}</span
    >

    <p class="mt-6 text-center">
        {$_(
            'Here is where you can change your login providers and wallet information',
        )}
    </p>

    <p class="mt-6 text-center font-semibold">wallet.hello.coop</p>

    <!-- svelte-ignore a11y_autofocus -->
    <button
        autofocus
        data-test="profile-landing-modal-ok-btn"
        onclick={close}
        class="btn-background mt-6 w-full">{$_('OK')}</button
    >
</FullPageModal>
