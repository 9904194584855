<script>
    import { _ } from 'svelte-i18n'
    import { onMount } from 'svelte'
    import { preventDefault } from '$utils/common.js'
    import { NAMES_MAP } from '$src/constants.js'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'

    let { addName = () => {}, type = '', ajaxRequest = false } = $props()

    let value = $state('')

    onMount(() => {
        if (type) document.getElementById(type).focus()
    })
</script>

<div class="text-center">
    <form onsubmit={preventDefault(() => addName(value))}>
        <div>
            <div class="my-2">
                <input
                    type="text"
                    name={type}
                    id={type}
                    bind:value
                    autocomplete={type?.replaceAll('_', '-')}
                    required
                    placeholder={$_(NAMES_MAP[type])}
                    class="h-12 w-full pl-[16px] sm:pl-[18px]"
                />
            </div>
            <button
                data-test="confirm-add-name-btn"
                type="submit"
                disabled={value.length <= 1 || ajaxRequest}
                class="btn-background h-12 w-full transition disabled:cursor-not-allowed disabled:opacity-50"
            >
                {#if ajaxRequest}
                    <SpinnerIcon css="h-5 w-5 mx-auto text-white" />
                {:else}
                    {$_('Add name')}
                {/if}
            </button>
        </div>
    </form>
</div>
