<script>
    import { onMount } from 'svelte'
    import ChooseManaged from '$lib/ChooseManaged.svelte'
    import Notification from '$lib/Notification.svelte'
    import TitleBar from '$lib/TitleBar.svelte'
    import { notification } from '$src/stores.js'
    import { getProfile, getManagedLogos } from '$utils/api.js'
    import { global } from '$src/state.svelte.js'
    import { setAttributes } from '$utils/common'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import { replace as replaceRoute } from 'svelte-spa-router'

    let states = $state({
        PICK_IMAGE: false,
    })

    let managedLogos = $state(null)

    onMount(async () => {
        global.spinner = true

        //we dont have profile profileData
        if (!global.data?.version) {
            global.data = await getProfile()
        }

        if (global.data.chooseManagedLogo) {
            managedLogos = await getManagedLogos(
                global.data.chooseManagedLogo.id,
            )
            states.PICK_IMAGE = true
        }

        if (!global.data.chooseManagedLogo && !global.data.chooseWhoManages)
            return replaceRoute('/')

        logPlausibleEvent({ u: '/wizard/managed' })

        global.spinner = false
    })
</script>

<TitleBar showMenu={false} />

{#if $notification.text}
    <Notification />
{/if}

{#if !global.spinner}
    <div class="flex-1 overflow-y-auto">
        <div class="px-outer mx-auto w-full max-w-[856px]">
            <main class="gap-x-col flex flex-col items-center pb-12 pt-6">
                <div class="max-w-col px-indent mt-2 w-full">
                    <ChooseManaged bind:states {managedLogos} />
                </div>
            </main>
        </div>
    </div>

    <wc-footer use:setAttributes></wc-footer>
{/if}
