<script>
    import tippy from 'sveltejs-tippy'
    import * as sort from '$utils/sort'
    import { DEFAULT_MANAGED_LOGO } from '$src/constants.js'
    import { FLIP_ANIM_MS, SLIDE_ANIM_MS } from '$src/constants.js'
    import { _, locale } from 'svelte-i18n'
    import { flip } from 'svelte/animate'
    import { slide, fly } from 'svelte/transition'
    import { global } from '$src/state.svelte.js'
    import { dedupe, getDisplay } from '$utils/common'
    import ManagedProviderDropdown from '$lib/ManagedProviderDropdown.svelte'
    import HelpButton from '$lib/button/HelpButton.svelte'
    import Modal from '$lib/modal/Modal.svelte'
    import RemoveButton from '$lib/button/RemoveButton.svelte'
    import RemoveIconButton from '$lib/button/RemoveIconButton.svelte'
    import PromoteIcon from '$lib/icon/PromoteIcon.svelte'
    import DemoteIcon from '$lib/icon/DemoteIcon.svelte'

    let {
        editMode = false,
        dropdownStates = $bindable({}),
        confirmProviderDelete = $bindable(),
        removeProviderAjax = false,
        removeUnverifiedProviderAjax = false,
        removeProvider = () => {},
        removeUnverifiedProvider = () => {},
        makeRecovery = () => {},
        removeRecovery = () => {},
        handleDropdown = () => {},
        verifyEmailSuccess = () => {},
    } = $props()
</script>

<table id="managed-providers" class="mt-10 w-full table-fixed">
    <thead>
        <tr class="relative">
            <th class="w-1/2 text-left text-lg font-medium md:w-3/5">
                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <span>{$_('Managed Providers')}</span>
                        <HelpButton
                            ariaLabel="Help"
                            content={$_(
                                'Accounts managed by your employer, school, or other organization',
                            )}
                            placement="top"
                        />
                    </div>
                </div>
            </th>
        </tr>
    </thead>
</table>

<div class="mt-2 w-full space-y-2">
    <!-- Checking doneWizardAt here because we don't want to sort accounts in wizard flow -->
    {#if global.data.profile.accounts.length && global.data.actions?.doneWizardAt}
        {#each dedupe(global.data.profile.accounts, 'id')
            .filter((i) => i.managed)
            .sort(sort.accounts) as account, index (account.id)}
            {@const recoveryCount = global.data.profile.accounts.filter(
                (i) => !i.preferred && i.recovery,
            ).length}
            <div
                data-test="profile-managed-provider-{index}"
                class="relative flex items-center justify-between"
                animate:flip={{ duration: FLIP_ANIM_MS }}
                transition:slide={{ duration: SLIDE_ANIM_MS }}
            >
                <!-- (account.verifiedAt check) Delete verified vs unverified -->
                {#if confirmProviderDelete && (account.verifiedAt ? confirmProviderDelete === account.id : confirmProviderDelete === account.user_name)}
                    <Modal
                        position="left"
                        css="top-10 md:min-w-[22rem]"
                        close={() => (confirmProviderDelete = null)}
                    >
                        <div
                            class="flex flex-col items-center justify-between md:flex-row"
                        >
                            <span>{$_('Confirm Deletion')}</span>
                            <div
                                class="mt-4 flex items-center gap-x-4 md:mt-0 md:space-x-2"
                            >
                                <button
                                    onclick={() =>
                                        (confirmProviderDelete = null)}
                                    class="btn-border h-9 w-20 px-0 text-sm"
                                    >{$_('Cancel')}</button
                                >
                                <RemoveButton
                                    dataTest="remove-provider-{index}-btn-confirm"
                                    onclick={() => {
                                        account.verifiedAt
                                            ? removeProvider(account.id)
                                            : removeUnverifiedProvider(
                                                  account.user_name,
                                              )
                                    }}
                                    isLoading={account.verifiedAt
                                        ? removeProviderAjax === account.id
                                        : removeUnverifiedProviderAjax ===
                                          account.user_name}
                                    value="Delete"
                                />
                            </div>
                        </div>
                    </Modal>
                {/if}

                <div
                    data-test="managed-providers-list"
                    class="flex w-full items-center truncate"
                >
                    <div
                        class="mt-1 inline-flex w-6 flex-shrink-0 items-center justify-start py-3 md:mt-0 md:py-0"
                    >
                        {#if editMode}
                            <RemoveIconButton
                                dataTest="remove-provider-{index}-btn"
                                onclick={() =>
                                    account.verifiedAt
                                        ? (confirmProviderDelete = account.id)
                                        : (confirmProviderDelete =
                                              account.user_name)}
                            />
                        {/if}
                    </div>
                    <div
                        class="bg-charcoal relative flex w-full items-start truncate rounded-md bg-opacity-10 px-4 py-3 md:items-center md:py-[7px] dark:bg-opacity-100"
                    >
                        <span
                            class=" managed-rounded-square-sm h-[54px] w-[54px] md:h-[34px] md:w-[34px]"
                        >
                            <img
                                src={account.logo || DEFAULT_MANAGED_LOGO}
                                alt={account.user_name}
                                class="md:w-4.5 max-h-[36px] w-9 object-contain md:max-h-[18px]"
                            />
                        </span>
                        <div
                            class="ml-3 flex w-full flex-col truncate px-0.5 md:flex-row"
                        >
                            <span
                                data-test="profile-managed-provider-{index}-label"
                                class="flex-1 truncate font-medium"
                            >
                                {account.user_name} ({getDisplay(account.slug)})
                            </span>
                            <div
                                class="flex items-center justify-start gap-x-2 py-0.5 {$locale &&
                                $locale.startsWith('ar')
                                    ? 'mr-0 md:mr-3'
                                    : 'ml-0 md:ml-3'} md:mt-0"
                            >
                                {#if !account.preferred && account.recovery && account.verifiedAt && !account.linkedAccount}
                                    {#if recoveryCount <= 3}
                                        <button
                                            use:tippy={{
                                                content: $_(
                                                    'You cannot demote your only {count} recovery provider(s)',
                                                    {
                                                        values: {
                                                            count: recoveryCount,
                                                        },
                                                    },
                                                ),
                                                placement: 'top',
                                            }}
                                            data-test="demote-{index}-btn"
                                            transition:fly={{
                                                x: 10,
                                                duration: SLIDE_ANIM_MS,
                                            }}
                                            class="btn-border h-4.5 w-4.5 relative flex cursor-not-allowed items-center justify-center overflow-hidden text-xs opacity-50"
                                        >
                                            <DemoteIcon />
                                        </button>
                                    {:else}
                                        <button
                                            data-test="demote-{index}-btn"
                                            transition:fly={{
                                                x: 10,
                                                duration: SLIDE_ANIM_MS,
                                            }}
                                            onclick={() =>
                                                removeRecovery(account)}
                                            class="btn-border h-4.5 w-4.5"
                                            disabled={recoveryCount <= 3}
                                        >
                                            <DemoteIcon />
                                        </button>
                                    {/if}
                                {/if}

                                {#if account.recovery}
                                    <span
                                        transition:fly={{
                                            x: 10,
                                            duration: SLIDE_ANIM_MS,
                                        }}
                                        class="inline-block truncate text-sm font-bold uppercase
                                    {$locale && $locale.startsWith('ar')
                                            ? 'text-right md:text-left'
                                            : 'text-left md:text-right'} flex-shrink-0"
                                    >
                                        {$_('Recovery')}
                                    </span>
                                {/if}

                                {#if account.recovery}
                                    <button
                                        use:tippy={{
                                            content: $_(
                                                'Managed providers cannot be upgraded to preferred',
                                            ),
                                            placement: 'top',
                                        }}
                                        data-test="promote-{index}-btn"
                                        transition:fly={{
                                            x: 10,
                                            duration: SLIDE_ANIM_MS,
                                        }}
                                        class="btn-border h-4.5 w-4.5 relative flex items-center justify-center overflow-hidden text-xs font-medium"
                                    >
                                        ?
                                    </button>
                                {:else}
                                    <button
                                        use:tippy={{
                                            content: $_('Make Recovery'),
                                            placement: 'top',
                                        }}
                                        data-test="promote-{index}-btn"
                                        onclick={() => makeRecovery(account)}
                                        class="btn-border h-4.5 w-4.5"
                                    >
                                        <PromoteIcon />
                                    </button>
                                {/if}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {:else}
            {#if editMode}
                <span
                    class="text-fallback text-sm mt-2 {$locale &&
                    $locale.startsWith('ar')
                        ? 'mr-6'
                        : 'ml-6'}">{$_('No managed providers')}</span
                >
            {/if}
        {/each}
    {/if}
</div>

{#if !editMode}
    <section
        class="mt-2 space-y-2 {$locale && $locale.startsWith('ar')
            ? 'mr-6'
            : 'ml-6'} p-0.5"
        transition:slide={{ duration: SLIDE_ANIM_MS }}
    >
        <div class="relative flex items-center">
            <ManagedProviderDropdown
                label={$_('Link a managed provider')}
                bind:expanded={dropdownStates.managedProvider}
                managedEmailSuccess={verifyEmailSuccess}
                {handleDropdown}
                showIcon={false}
            />
        </div>
    </section>
{/if}
