<script>
    import { locale } from 'svelte-i18n'

    let { dir = 'left', hasBackground = false } = $props()

    let rotateClass = $state()

    if (dir === 'right') {
        if ($locale && !$locale.startsWith('ar')) {
            rotateClass = 'rotate-180'
        }
    } else {
        if ($locale && $locale.startsWith('ar')) {
            rotateClass = 'rotate-180'
        }
    }
</script>

<svg
    width="80"
    height="38"
    viewBox="0 0 80 38"
    fill={hasBackground ? '#303030' : 'none'}
    xmlns="http://www.w3.org/2000/svg"
    class="group-hover:dark:stroke-3 group-focus:dark:stroke-3 text-charcoal scale-[1.4] transform stroke-1 group-hover:stroke-2 group-focus:stroke-2 dark:stroke-2 dark:text-[#808080] {rotateClass ||
        ''}"
>
    <path
        d="M3.21616 14.8007L7.06016 10.4874L13.9389 3.19777C15.1308 1.93466 16.7906 1.21875 18.5274 1.21875H72.1189C75.6032 1.21875 78.4277 4.0433 78.4277 7.52757V30.4688C78.4277 33.9531 75.6032 36.7776 72.1189 36.7776H18.5274C16.7906 36.7776 15.1308 36.0617 13.9389 34.7986L7.06016 27.5089L3.21615 23.1956C1.0842 20.8034 1.0842 17.1929 3.21616 14.8007Z"
        stroke="currentColor"
    />
</svg>
