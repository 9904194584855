<script>
    import { onMount } from 'svelte'
    import { _ } from 'svelte-i18n'
    import TitleBar from '$lib/TitleBar.svelte'
    import { setAttributes } from '$utils/common.js'
    // import {
    // 	promptForPasskey,
    // } from '$utils/passkey.js';
    import { logPlausibleEvent } from '$utils/plausible.js'
    import Notification from '$lib/Notification.svelte'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { startRegistration } from '@simplewebauthn/browser'
    import {
        getProfile,
        getCreateChallenge,
        postNoPromptPasskey,
        postCreateChallenge,
    } from '$utils/api.js'
    import {
        push as navigateTo,
        replace as replaceRoute,
    } from 'svelte-spa-router'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import PasskeyIcon from '$lib/icon/PasskeyIcon.svelte'

    onMount(async () => {
        global.spinner = true

        //we dont have profile data
        if (!global.data?.version) {
            global.data = await getProfile()
        }

        if (!global.data.isPersonalLoggedIn && !global.data?.isManagedLoggedIn)
            return replaceRoute('/login')

        if (!global.data?.preferred?.length)
            return replaceRoute('/wizard/preferredprovider')

        //uncomment to start prompting for passkey
        // const showPromptForPasskey = await promptForPasskey(global.data);
        // if (!showPromptForPasskey) return replaceRoute('/');

        logPlausibleEvent({ u: '/passkey' })
        global.spinner = false
    })

    let passkeyAjax = $state(false)
    async function createPasskey() {
        passkeyAjax = true
        const createCredentialOpts = await getCreateChallenge()
        const createCredentialRes =
            await startRegistration(createCredentialOpts)
        const postCredentialRes = await postCreateChallenge(createCredentialRes)
        if (!postCredentialRes?.verified) throw postCredentialRes
        global.data = await getProfile()
        setTimeout(() => {
            //so it animates in
            notification.show('Hellō Passkey created on this device', 'success')
        }, 150)
        return navigateTo('/')
    }

    async function noPromptPasskey() {
        await postNoPromptPasskey()
        global.data = await getProfile()
        return navigateTo('/')
    }

    function remindLater() {
        sessionStorage.setItem('remindPasskeyPromptLater', true)
        return navigateTo('/')
    }
</script>

<TitleBar />

{#if $notification.text}
    <Notification />
{/if}

{#if !global.spinner}
    <main
        class="stack:py-[5vh] gap-x-col flex flex-1 flex-col items-center py-4 [@media(min-height:1216px)]:!py-16"
    >
        <div>
            <PasskeyIcon css="mx-auto h-9" />

            <h1 class="my-2 flex-1 border-none text-center text-lg font-medium">
                {$_('Use a passkey for faster, safer log in')}
            </h1>
            <p class="text-center text-sm">
                {$_('Log in using your face, fingerprint, or device PIN')}
            </p>

            <div class="mx-auto my-6 max-w-xs space-y-3 text-sm">
                <button
                    onclick={createPasskey}
                    disabled={passkeyAjax}
                    class="btn-background flex h-10 w-full items-center justify-center"
                >
                    {#if passkeyAjax}
                        <SpinnerIcon css="h-5 w-5 block mx-auto" />
                    {:else}
                        <PasskeyIcon css="h-4 w-4" />
                        <span class="ml-2">{$_('Create Hellō Passkey')}</span>
                    {/if}
                </button>
                <button onclick={remindLater} class="btn-border h-10 w-full"
                    >{$_('Remind me later')}</button
                >
            </div>
            <button
                onclick={noPromptPasskey}
                class="mx-auto block text-sm hover:underline focus:underline"
                >{$_("Don't ask again on this device")}</button
            >
        </div>
    </main>

    <wc-footer use:setAttributes></wc-footer>
{/if}
