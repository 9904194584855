<script>
    import { onMount } from 'svelte'
    import { fade } from 'svelte/transition'
    import { _ } from 'svelte-i18n'
    import {
        push as navigateTo,
        replace as replaceRoute,
    } from 'svelte-spa-router'
    import TitleBar from '$lib/TitleBar.svelte'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { getProfile, postWizard, keepAlive } from '$utils/api.js'
    import WizardProgress from '$lib/WizardProgress.svelte'
    import Notification from '$lib/Notification.svelte'
    import { setAttributes } from '$utils/common.js'
    import { clearLocalAndSessionStorage } from '$utils/storage.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import PointerIcon from '$lib/icon/PointerIcon.svelte'
    import InfoIcon from '$lib/icon/InfoIcon.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import { FADE_ANIM_DELAY_MS } from '$src/constants.js'

    let nextAjax = $state(false)

    onMount(async () => {
        global.spinner = true

        //we dont have profile data
        if (!global.data?.version) {
            global.data = await getProfile()
            if (
                !global.data.isPersonalLoggedIn &&
                !global.data?.isManagedLoggedIn
            ) {
                clearLocalAndSessionStorage()
                return replaceRoute('/login')
            }

            if (global.data.actions?.doneWizardAt) return replaceRoute('/')

            //we have 2 recoveries
            if (
                global.data.profile?.accounts?.filter(
                    (i) => i.recovery && !i.preferred && !i.communal,
                ).length >= 2
            )
                return replaceRoute('/wizard/status')
        }

        if (!global.data?.preferred?.length)
            return replaceRoute('/wizard/preferredprovider')

        localStorage.setItem('currentWizardStage', 'incomplete')
        logPlausibleEvent({ u: '/wizard/incomplete' })

        global.spinner = false
    })

    async function next() {
        nextAjax = true
        if (!global.data.actions?.doneWizardAt) {
            await postWizard()
            //End of Wizard Funnel
            sessionStorage.removeItem('wiz_funnel')
        }
        global.data = await getProfile()
        localStorage.setItem('showProfileLandingModal', true)
        localStorage.removeItem('currentWizardStage')
        notification.clear()
        navigateTo('/')
    }

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            next()
        }
    }
</script>

<TitleBar />

{#if $notification.text}
    <Notification />
{/if}

<svelte:window onkeydown={handleEnterKey} />

{#if global.data?.isPersonalLoggedIn}
    <main class="flex-1 overflow-y-auto pb-16">
        <div class="mt-8">
            {#if global.data.preferred?.length}
                <div
                    class="mx-auto flex max-w-2xl items-center justify-between space-x-2 px-3 text-sm md:space-x-0"
                    in:fade|global={{ delay: 0 * FADE_ANIM_DELAY_MS }}
                >
                    <h1
                        class="flex-1 border-none text-center text-xl font-medium"
                    >
                        {$_("This is how you'll be logging in")}
                    </h1>
                </div>
            {/if}
            <div class="mx-auto px-3 text-center md:max-w-2xl md:px-0">
                <WizardProgress />
                <div
                    class="mx-auto -mt-4 flex w-full max-w-md items-center gap-x-2 text-sm opacity-80"
                    in:fade|global={{ delay: 5 * FADE_ANIM_DELAY_MS }}
                >
                    <InfoIcon />
                    <span class="text-left"
                        >{$_(
                            'You can change your providers at wallet.hello.coop',
                        )}</span
                    >
                </div>
                <h1
                    class="mx-auto mt-6 w-full max-w-md rounded-md border-none bg-red-500 px-1 py-2 text-center text-base font-medium uppercase text-white md:hidden md:text-2xl dark:text-white dark:text-opacity-80"
                    in:fade|global={{ delay: 5 * FADE_ANIM_DELAY_MS }}
                >
                    {@html $_('Recovery Setup<br/>Incomplete')}
                </h1>
                <div
                    class="mx-auto mt-6 flex max-w-md items-center justify-between gap-x-6 px-3 text-sm md:max-w-2xl md:gap-x-8"
                    in:fade|global={{ delay: 5 * FADE_ANIM_DELAY_MS }}
                >
                    <button
                        data-test="back-btn"
                        onclick={() => {
                            keepAlive()
                            navigateTo('/wizard/recoveryprovider')
                        }}
                        class="group relative inline-flex w-20 items-center justify-center"
                    >
                        <span class="absolute ml-2">{$_('Back')}</span>
                        <!-- Back Arrow -->
                        <PointerIcon dir="left" />
                    </button>
                    <h1
                        class="hidden w-full max-w-md rounded-md border-none bg-red-500 px-1 py-2 text-center text-base font-medium uppercase text-white md:block md:text-2xl dark:text-white dark:text-opacity-80"
                    >
                        {@html $_('Recovery Setup<br/>Incomplete')}
                    </h1>
                    <button
                        data-test="skip-btn"
                        disabled={nextAjax}
                        onclick={next}
                        class="group relative inline-flex w-20 items-center justify-center disabled:cursor-not-allowed disabled:opacity-50"
                    >
                        <span class="absolute mr-1">
                            {#if nextAjax}
                                <SpinnerIcon css="h-5 w-5" />
                            {:else}
                                {$_('Skip')}
                            {/if}
                        </span>
                        <!-- Next arrow -->
                        <PointerIcon dir="right" />
                    </button>
                </div>
            </div>
        </div>
    </main>

    <wc-footer use:setAttributes></wc-footer>
{/if}
