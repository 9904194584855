<script>
    import { _ } from 'svelte-i18n'
    import { push as navigateTo } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { deleteProfile } from '$utils/api.js'
    import { preventDefault } from '$utils/common.js'
    import { prepareWalletData } from '$utils/profile.js'
    import { clearLocalAndSessionStorage } from '$utils/storage.js'
    import FullPageModal from '$lib/modal/FullPageModal.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import CloseIcon from '$lib/icon/CloseIcon.svelte'
    import TrashIcon from '$lib/icon/TrashIcon.svelte'
    import DownloadIcon from '$lib/icon/DownloadIcon.svelte'

    let { editMode = false } = $props()

    let deleteConfirmationInput = $state('')
    let confirmProfileDelete = $state(false)

    function downloadData() {
        const data = prepareWalletData(global.data)
        const dlAnchorElem = document.getElementById('downloadAnchorElem')
        dlAnchorElem.setAttribute('href', data)
        const fileName = 'hello_wallet.json'
        dlAnchorElem.setAttribute('download', fileName)
        dlAnchorElem.click()
    }

    let delProfileAjax = $state(false)
    async function delProfile() {
        delProfileAjax = true
        await deleteProfile()
        clearLocalAndSessionStorage()
        global.data = {}
        navigateTo('/login')
    }
</script>

<section class="relative my-8">
    <div class="mt-2 flex flex-wrap items-center justify-between gap-4">
        <button
            onclick={downloadData}
            data-test="download-data-btn"
            class="btn-border inline-flex h-9 items-center gap-x-1 px-4 font-medium"
        >
            <DownloadIcon />
            <span>{$_('Download Your Data')}</span>
        </button>
        <a aria-label="download data button" id="downloadAnchorElem" hidden></a>

        {#if editMode}
            <button
                data-test="profile-delete-btn"
                onclick={() => (confirmProfileDelete = true)}
                class="btn-danger inline-flex h-9 items-center gap-x-1 px-4 font-medium"
            >
                <TrashIcon />
                <span>{$_('Delete Your Wallet')}</span>
            </button>
        {/if}
    </div>

    {#if editMode && confirmProfileDelete}
        <FullPageModal
            dataTest="profile-delete-modal"
            close={() => {
                confirmProfileDelete = false
                deleteConfirmationInput = ''
            }}
        >
            <button
                onclick={() => {
                    confirmProfileDelete = false
                    deleteConfirmationInput = ''
                }}
                aria-label="Close"
                class="group absolute right-4 top-6"
            >
                <CloseIcon />
            </button>
            <p class="text-center">
                {@html $_(
                    'Once you delete your wallet,<br/>there is no going back<br/>Please type DELETE to confirm',
                )}
            </p>
            <form onsubmit={preventDefault(delProfile)} class="w-full">
                <!-- svelte-ignore a11y_autofocus -->
                <input
                    autofocus
                    data-test="profile-delete-input"
                    bind:value={deleteConfirmationInput}
                    type="text"
                    name="delete-confirmation-input"
                    class="my-4 h-12 w-full px-4"
                    placeholder="DELETE"
                />
                <button
                    data-test="confirm-profile-delete-btn"
                    disabled={deleteConfirmationInput !== 'DELETE' ||
                        delProfileAjax}
                    type="submit"
                    class="btn-danger-background inline-flex h-12 w-full items-center justify-center"
                >
                    {#if delProfileAjax}
                        <SpinnerIcon css="h-6 w-6" />
                    {:else}
                        {$_('Permanently delete your wallet')}
                    {/if}
                </button>
            </form>
        </FullPageModal>
    {/if}
</section>
