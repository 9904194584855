<script>
    import { _ } from 'svelte-i18n'
    import { onDestroy } from 'svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'

    let {
        value = '',
        isLoading = false,
        dataTest,
        onclick = () => {},
    } = $props()

    $effect(() => {
        isLoading
            ? document.body.classList.add('cursor-wait')
            : document.body.classList.remove('cursor-wait')
    })

    onDestroy(() => {
        document.body.classList.remove('cursor-wait')
    })
</script>

<button
    data-test={dataTest}
    {onclick}
    class="btn-border inline-flex h-6 w-32 items-center justify-center truncate px-1 text-xs font-bold uppercase uppercase"
    class:bg-opacity-50={isLoading}
    class:cursor-not-allowed={isLoading}
    disabled={isLoading}
>
    {#if isLoading}
        <SpinnerIcon css="h-4 w-4" />
    {:else}
        <span class="truncate">{$_(value)}</span>
    {/if}
</button>
