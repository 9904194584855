<script>
    import { _ } from 'svelte-i18n'
    import { onDestroy } from 'svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'

    let {
        value = '',
        isLoading = false,
        dataTest = '',
        onclick = () => {},
    } = $props()

    $effect(() => {
        isLoading
            ? document.body.classList.add('cursor-wait')
            : document.body.classList.remove('cursor-wait')
    })

    onDestroy(() => {
        document.body.classList.remove('cursor-wait')
    })
</script>

<!-- svelte-ignore a11y_autofocus -->
<button
    autofocus
    data-test={dataTest}
    {onclick}
    class="btn-background inline-flex h-9 w-20 items-center justify-center px-0 text-sm"
    class:bg-opacity-50={isLoading}
    class:cursor-not-allowed={isLoading}
    disabled={isLoading}
>
    {#if isLoading}
        <SpinnerIcon css="h-4 w-4" />
    {:else}
        {$_(value)}
    {/if}
</button>
